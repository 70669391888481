import React, { Component } from 'react';

export class AboutPage extends Component {
    render() {
        return (
            <div>
                <h1>About</h1>
            </div>
        );
    }
}

export default AboutPage;
